import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import illustration from '../../../resources/lottie-animations/accessibility.json';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnimatedIllustration = makeShortcode("AnimatedIllustration");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimatedIllustration data={illustration} css={{
      marginBottom: 32
    }} mdxType="AnimatedIllustration" />
    <h1 {...{
      "id": "canvas-inclusion-practices"
    }}>{`Canvas Inclusion Practices`}</h1>
    <h2 {...{
      "id": "design-philosophy"
    }}>{`Design Philosophy`}</h2>
    <p>{`Workday appreciates that users have different abilities, and we strive to produce solutions that
meet the needs of a diverse audience—including those with disabilities that use assistive technology
to access digital content. By including and respecting diversity in our design process, we ensure
that everyone can access the content they need.`}</p>
    <p>{`We are guided by the
`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/"
      }}>{`Web Content Accessibility Guidelines (WCAG) 2.1 A/AA`}</a>{` in how users
access and consume our content. The principles of the WCAG are a technology-neutral approach to
ensure that users can find, access, and manipulate information reliably across a range of devices.
Workday strives to ensure those principles are included in every step of our design process.`}</p>
    <h2 {...{
      "id": "everyone-benefits-from-accessibility"
    }}>{`Everyone Benefits from Accessibility`}</h2>
    <p>{`Accessibility efforts typically aim to serve users who may have an impairment affecting the
following:`}</p>
    <ul>
      <li parentName="ul">{`Vision`}</li>
      <li parentName="ul">{`Auditory Sense`}</li>
      <li parentName="ul">{`Motor Function`}</li>
      <li parentName="ul">{`Cognitive Ability`}</li>
    </ul>
    <p>{`However, web accessibility is not limited to persons with permanent disabilities. Considering the
needs of users with disabilities when designing makes the experience better and more robust for all
users. For example, using colors that have sufficient contrast to background content is not only an
aid to users with a visual impairment but also beneficial to everyone looking at a screen in bright
daylight.`}</p>
    <h2 {...{
      "id": "categories-of-accessibility"
    }}>{`Categories of Accessibility`}</h2>
    <p>{`We continually improve our products and services in four key categories of accessibility:`}</p>
    <h3 {...{
      "id": "perceivable"
    }}>{`Perceivable`}</h3>
    <p>{`Information and user-interface components must be presentable to users in ways they can sense.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Example:`}</strong>{` Do users know that there is a button on the webpage?`}</li>
    </ul>
    <h3 {...{
      "id": "operable"
    }}>{`Operable`}</h3>
    <p>{`Users must be able to operate the interface. The interface cannot require interaction that a user
cannot perform.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Example:`}</strong>{` Can users activate the button?`}</li>
    </ul>
    <h3 {...{
      "id": "understandable"
    }}>{`Understandable`}</h3>
    <p>{`Users must be able to understand the information as well as the operation of the user interface. The
content or operation cannot be beyond their understanding.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Example:`}</strong>{` Does the button have a descriptive label? Is it clear to the user what will occur if
they interact with the button?`}</li>
    </ul>
    <h3 {...{
      "id": "robust"
    }}>{`Robust`}</h3>
    <p>{`Content must be robust enough that it can be interpreted reliably by a wide variety of assistive
technologies. As technologies advance and browser and assistive technologies evolve, the content
should remain accessible.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Example:`}</strong>{` Does the button work reliably and predictably across broad range of devices?`}</li>
    </ul>
    <p>{`More detail on these categories and on the rest of the guidelines can be found at
`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/"
      }}>{`Web Content Accessibility Guidelines (WCAG) 2.1`}</a></p>
    <h2 {...{
      "id": "approach"
    }}>{`Approach`}</h2>
    <p>{`Accessibility is a multifaceted challenge that requires a variety of methods to ensure that all
users can access content in a way that works for them.`}</p>
    <p>{`As part of our process, Workday fosters understanding of the different experiences that users face.
To do this, we:`}</p>
    <ul>
      <li parentName="ul">{`Promote human-centered design and usability for all`}</li>
      <li parentName="ul">{`Design products with POUR (perceivable, operable, understandable, and robust) principles in mind`}</li>
      <li parentName="ul">{`Work closely with all stakeholders, including our customer partners and end users`}</li>
      <li parentName="ul">{`Solicit feedback on features and functionality from a diverse range of people, including those
with disabilities`}</li>
      <li parentName="ul">{`Host workshops facilitated by accessibility specialists`}</li>
      <li parentName="ul">{`Encourage use of our accessibility research and training lab`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`You can reach out to your accessibility team on Slack at either
`}<a parentName="p" {...{
          "href": "https://workday.enterprise.slack.com/archives/C01SLQWB7S4"
        }}>{`#product-equity-inclusion`}</a>{` or
`}<a parentName="p" {...{
          "href": "//workday-dev.slack.com/archives/C0ENM76V7"
        }}>{`#accessibility`}</a>{`.`}</p>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      